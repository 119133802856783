
import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import {useNavigate} from "react-router-dom";
import H2Header from "../../headers/h2";
import DefaultLayoutWithoutSideBar from "../../../layouts/DefaultLayoutWithoutSideBar";
import H1Header from "../../headers/h1";
import TextInput from "../../input/TextInput";
import NumberInput from "../../input/NumberInput";
import {ValidationContext} from "../../contexts/ValidationContext";
import {SmallTable} from "../../tables/SmallTable";
import NewChildModal from "../../modals/NewChildModal";
import EditChildModal from "../../modals/EditChildModal";
import {MdEdit} from "react-icons/md";
import PresentSelectionModal from "../../modals/PresentSelectionModal";


function VisitorOverviewPage() {

    const {Url } = useContext(ApiContext)
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    const { token } = useAuth();
    const [loading, setLoading] = useState(false)
    const [loadingVisitInfo, setLoadingVisitInfo] = useState(false)
    const [dataAvailableProfile, setDataAvailableProfile] = useState(false)
    const [dataAvailableVisitorInfo, setDataAvailableVisitor] = useState(false)
    const [openAddChildModal, setOpenAddChildModal] = useState(false);
    const [openEditChildModal, setOpenEditChildModal] = useState(false);
    const [openPresentSelectionModal, setOpenPresentSelectionModal] = useState(false);
    const [rowData, setRowData] = useState([])
    const [rowDataPresent, setRowDataPresent] = useState([])
    const [visitorData, setVisitorData] = useState([])
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [adultAmount, setAdultAmount] = useState('')
    const [adultNames, setAdultNames] = useState('')
    const [message, setMessage] = useState('')

    const getColumnsChildren = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            Header: "Leeftijd",
            accessor: "Age",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }

    ];
    const getColumnsChildrenLocked = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            Header: "Leeftijd",
            accessor: "Age",
        },
    ];
    const getColumnsPresents = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Cadeau",
            accessor: "PresentName",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }

    ];
    const getColumnsPresentsLocked = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Cadeau",
            accessor: "PresentName",
        },

    ];
    function handleRowData (rowDataInput){
        setRowData(rowDataInput)
        if (rowData){
            setOpenEditChildModal(true)
        }
    }
    function handleRowDataPresents (rowDataInput){
        setRowDataPresent(rowDataInput)
        if (setRowDataPresent){
            setOpenPresentSelectionModal(true)
        }
    }

    function saveProfile(){
        setMessage("Bezig met opslaan...")
        fetch(Url + 'user/profile', {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                FirstName : firstName,
                LastName: lastName,
                AdultAmount: Number(adultAmount),
                AdultNames: adultNames
            }),
        }).then((res) => {
            if (res.status === 200) {
                //Saving successfully.
                setLoading(false)
                setMessage("Het opslaan is gelukt")
            } else {
                //Saving failed
                setMessage("Het opslaan is mislukt, neem contact op.")
            }
        })
    }

    useEffect(() => {
        if (token) {
            setLoading(true)
            fetch(Url + 'user/profile', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setDataAvailableProfile(true)
                        return res.json()
                    }else{
                        setDataAvailableProfile(false)
                        return [{
                            "message": "Geen Data beschikbaar over jouw profiel"
                        }]
                    }
                })
                .then((data) => {
                    setFirstName(data.FirstName)
                    setLastName(data.LastName)
                    setAdultAmount(data.CompanyVisitorsAmount)
                    setAdultNames(data.CompanyVisitorNames)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [token, Url]);
    useEffect(() => {
        if (token) {
            setLoadingVisitInfo(true)
            fetch(Url + 'user/company-visitor-info', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setDataAvailableVisitor(true)
                        return res.json()
                    }else{
                        setDataAvailableVisitor(false)
                        return [{
                            "message": "Geen Data beschikbaar over jouw bezoeken"
                        }]
                    }
                })
                .then((data) => {
                    setVisitorData(data)

                })
                .finally(() => {
                    setLoadingVisitInfo(false)
                })
        }
    }, [token, Url, openAddChildModal, openEditChildModal, openPresentSelectionModal]);
    let profileData
    if (dataAvailableProfile){
        profileData = (
            <div>
                <H2Header headerText="Algemene gegevens"/>
                <div>
                    Welkom terug! Hier kan je je gegevens inzien en wijzigen. Let op, na de inschrijfdeadline kan je alleen nog maar tekstuele wijzigingen doorvoeren tot een week voor het bezoek. Zorg dus dat al je kinderen voor die tijd ingeschreven zijn.
                    Alle informatie wijzigt direct. Je kan deze pagina verlaten zodra je klaar bent.
                </div>
                <p className="text-base leading-relaxed text-red-500">
                    {message}
                </p>
                <TextInput
                    labelName="Jouw voornaam"
                    inputName="firstName"
                    placeholder="Voornaam"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    errorMessage={nameErrorMessage}
                    pattern={nameRegex}
                    type="text">
                </TextInput>
                <TextInput
                    labelName="Jouw achternaam"
                    inputName="lastName"
                    placeholder="Achternaam"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    errorMessage={nameErrorMessage}
                    pattern={nameRegex}
                    type="text">
                </TextInput>
                <NumberInput
                    labelName="Aantal volwassen personen die naar het feest komen."
                    inputName="alduts"
                    onChange={(e) => setAdultAmount(e.target.value)}
                    value={adultAmount}
                    placeholder="Aantal volwassen personen"
                    errorMessage="Minimaal 1 volwassen persoon moet zich opgeven. Gebruik alleen getallen."
                    type="number"
                    max={9}
                    min={1}
                />
                <TextInput
                    labelName="De namen van de volwassen personen die naar het feest komen."
                    inputName="aldutNames"
                    placeholder="Naam1, Naam2, Naam3"
                    onChange={(e) => setAdultNames(e.target.value)}
                    value={adultNames}
                    errorMessage={nameErrorMessage}
                    pattern={nameRegex}
                    type="text">
                </TextInput>
                <div className="flex justify-end">
                    <DefaultButton content="Bijwerken" onClick={saveProfile}></DefaultButton>
                </div>
            </div>
        )
    } else {
        profileData = (
            <div>Geen profiel in de database of je hebt geen rechten om deze informatie te bekijken.</div>
        )
    }

    let visitorDataView
    if (dataAvailableVisitorInfo && visitorData) {
        visitorDataView = (
            visitorData.map((companyVisit) =>
                <div className="" key={companyVisit.ID}>
                    <hr/>
                    <div className="grid grid-cols-3">
                        <div className="col-span-3">
                            <H2Header headerText={companyVisit.Company}/>
                            <p>Datum: {companyVisit.Date} </p>
                            <p>Locatie: {companyVisit.Address + ", " + companyVisit.Zipcode + ", " + companyVisit.City}</p>
                            <p className="text-2xl mt-4">Ingeschreven Kinderen</p>
                            {companyVisit.Locked && <p>De inschrijving is gesloten het is niet meer mogelijk om gegevens te wijzigen. Wil je toch nog iets doorgeven, neem dan contact op met je contactpersoon.</p>}
                            {!companyVisit.Locked && <p>Klik op de naam van een kind om de gegevens te wijzigen. Je kan kinderen toevoegen tot en
                                met {companyVisit.SoftSignUpDeadline}.</p>}

                            {!companyVisit.Locked && <SmallTable tableData={companyVisit.Children} setRowdata={setRowData}
                                        onRowClick={handleRowData}
                                        getColumns={getColumnsChildren()}></SmallTable>}
                            {companyVisit.Locked && <SmallTable tableData={companyVisit.Children} setRowdata={setRowData}
                                                                 getColumns={getColumnsChildrenLocked()} onRowClick={() =>alert("Wijzigen niet mogelijk")}></SmallTable>}
                            <div className="flex justify-end">
                                {!companyVisit.Locked && <DefaultButton content="Voeg kind toe"
                                               onClick={() => setOpenAddChildModal(true)}></DefaultButton>}
                                <NewChildModal openModal={openAddChildModal} setOpenModal={() => setOpenAddChildModal()}
                                               welcomePage={false} signUpCode={companyVisit.SignUpCode}/>
                                <EditChildModal openModal={openEditChildModal}
                                                setOpenModal={() => setOpenEditChildModal()}
                                                rowData={rowData} welcomePage={false}
                                                signUpCode={companyVisit.SignUpCode}/>
                            </div>
                            {companyVisit.PresentServiceActive && <p className="text-2xl mt-4">Cadeauselectie</p>}
                            {companyVisit.PresentServiceActive && !companyVisit.Locked && <p>Klik op de naam van een kind om het cadeau te wijzigen. Je kan cadeaus wijzigen tot en
                                met {companyVisit.SoftSignUpDeadline}.</p>}
                            {companyVisit.PresentServiceActive && companyVisit.Locked && <p>De inschrijving is gesloten het is niet meer mogelijk om de cadeautjes te wijzigen. Wil je toch nog iets doorgeven, neem dan contact op met je contactpersoon..</p>}
                            {companyVisit.PresentServiceActive && !companyVisit.Locked && <SmallTable tableData={companyVisit.PresentInfo} setRowdata={setRowData}
                                                                                                      onRowClick={handleRowDataPresents}
                                                                                                      getColumns={getColumnsPresents()}></SmallTable>}
                            {companyVisit.PresentServiceActive && companyVisit.Locked && <SmallTable tableData={companyVisit.PresentInfo} setRowdata={setRowData}
                                                                                                      getColumns={getColumnsPresentsLocked()} onRowClick={() =>alert("Wijzigen niet mogelijk")}></SmallTable>}
                            {companyVisit.PresentServiceActive && <PresentSelectionModal openModal={openPresentSelectionModal}
                                                   setOpenModal={() => setOpenPresentSelectionModal()}
                                                   presents={companyVisit.Presents}
                                                   rowData={rowDataPresent} welcomePage={false}/>}

                        </div>
                    </div>
                </div>
            )
        )
    }else{
        visitorDataView = (
            <div>
                <div>
                    Er staan op dit moment geen kinderen ingeschreven bij een bezoek. Inschrijven? Klik dan op de knop
                    hieronder.
                </div>
                <div className="mt-4">
                    <DefaultButton content="Inschrijven" onClick={() => navigate("/company/welcome/", {replace: false})}></DefaultButton>
                </div>
            </div>


        )

    }
    return (
        <DefaultLayoutWithoutSideBar>
            <BoxLayout>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div className="flex flex-col overflow-auto py-4 sm:py-0 m-4">
                        <H1Header headerText="Overzicht van jouw bezoeken"/>
                        {profileData}
                    </div>
                )}
                <hr/>
                {loadingVisitInfo ? (
                    <div>Loading...</div>
                ) : (
                    <div className="flex flex-col overflow-auto py-4 sm:py-0 m-4">
                        <H2Header headerText="Jouw bezoeken"/>
                        {visitorDataView}
                    </div>
                )}
            </BoxLayout>
        </DefaultLayoutWithoutSideBar>
    )
}

export default VisitorOverviewPage