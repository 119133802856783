import DefaultLayout from "../../../layouts/DefaultLayout";
import {Table} from "../../tables/BasicTable";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";
import {useNavigate} from "react-router-dom";
import NewCompanyVisitModal from "../../modals/NewCompanyVisitModal";


function AdminCompanyVisitsPage() {

    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [companyVisits, setCompanyVisits] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)
    const [openAddCompanyVisitModal, setOpenAddCompanyVisitModal] = useState(false);
    const [authorized, setAuthorized] = useState(false)
    const navigate = useNavigate();
    function handleRowData (rowDataInput){
        navigate("/admin/company-visit-details/" + rowDataInput.ID, { replace: false })
    }
    const getColumns = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Bedrijf",
            accessor: "Company",
        },
        {
            Header: "Datum",
            accessor: "Date",
        },
        {
            Header: "Start tijd",
            accessor: "StartTime",
        },
        {
            Header: "Eind tijd",
            accessor: "EndTime",
        },
        {
            Header: "Aantal kinderen",
            accessor: "NumberOfChildren",
        },
        {
            Header: "Cadeau service",
            accessor: "PresentCategory",
        },
        {
            Header: "Locked",
            accessor: "LockedText",
        },


    ];
    useEffect(() => {
        if (token) {
            try{
                setLoading(true)
                fetch(Url + 'admin/company-visits', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setDataAvailable(true)
                            setAuthorized(true)
                            return res.json()
                        }else{
                            if (res.status === 401){
                                setAuthorized(false)
                            }else{
                                setAuthorized(true)
                            }
                            setDataAvailable(false)
                            return [{
                                "message": "Geen bedrijfszoeken"
                            }]
                        }
                    })
                    .then((data) => {
                        setCompanyVisits(data)
                    })
                    .finally(() => {
                        setLoading(false)

                    })
            }catch (error){
                setDataAvailable(false)
                return [{
                    "message": "Laden niet gelukt."
                }]
            }


        }
    }, [token, Url, openAddCompanyVisitModal]);
    let dataTable
    if (dataAvailable){
        dataTable = (
            <Table tableData={companyVisits} onRowClick={handleRowData} getColumns={getColumns()}></Table>
        )
    }else{
        dataTable = (
            <BoxLayout><div>Geen bezoeken in de database of je hebt geen rechten om deze informatie te bekijken.</div></BoxLayout>
        )
    }
    return (
        <DefaultLayout>
            <div className="mb-4">
                <NewCompanyVisitModal openModal={openAddCompanyVisitModal} setOpenModal={() => setOpenAddCompanyVisitModal()}/>
                {authorized && <DefaultButton content="Nieuw bezoek"  onClick={() => setOpenAddCompanyVisitModal(true)} disabled={false}></DefaultButton>}
            </div>


            {loading ? (
                <BoxLayout><div>Loading...</div></BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    {dataTable}
                </div>
            )}
        </DefaultLayout>
    )
}
export default AdminCompanyVisitsPage